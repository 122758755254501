<template lang="">
    <div>

        <!--begin::Dashboard-->
   <div class="container">

        <div class="row">
            <div class="col col-xl-6 col-md-6 col-sm-12 col-xs-12 mt-2">
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">

                        <div class="d-flex align-items-center mt-4">
                            <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <div class="symbol-label" style="background-image:url('../media/users/blank.png')"></div>
                                <i class="symbol-badge bg-success"></i>
                            </div>
                            <div>
                                <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{currentUser.nombre}}</a>
                                <div class="text-muted">{{currentUser.institucion}}</div>
                                <div class="mt-2">
                                    <!-- <a href="#" class="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Mi perfil</a> -->
                                    <!-- <a href="#" class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1">Follow</a> -->
                                </div>
                            </div>
                            
                        </div>

                      <!--   <div class="card-spacer bg-white card-rounded flex-grow-1">
                            <div class="row m-0">
                                <div class="col px-8 py-6 mr-8">
                                    <div class="font-size-sm text-muted font-weight-bold"> Average Sale </div>
                                    <div class="font-size-h4 font-weight-bolder">$650</div>
                                </div>
                                <div class="col px-8 py-6">
                                    <div class="font-size-sm text-muted font-weight-bold"> Commission </div>
                                    <div class="font-size-h4 font-weight-bolder">$233,600</div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col px-8 py-6 mr-8">
                                    <div class="font-size-sm text-muted font-weight-bold"> Annual Taxes </div>
                                    <div class="font-size-h4 font-weight-bolder">$29,004</div>
                                </div>
                                <div class="col px-8 py-6">
                                <div class="font-size-sm text-muted font-weight-bold"> Annual Income </div>
                                <div class="font-size-h4 font-weight-bolder">$1,480,00</div></div></div></div> -->

                        <div class="py-9">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Correo:</span>
                                <a href="#" class="text-muted text-hover-primary">{{currentUser.correo}}</a>
                            </div>
                            <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Teléfono:</span>
                                <span class="text-muted">+50234254578</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Dirección:</span>
                                <span class="text-muted">Melbourne</span>
                            </div>
                             <div class="d-flex align-items-center justify-content-between">
                                <span class="font-weight-bold mr-2">Institución:</span>
                                <a href="#" class="text-muted text-hover-primary">SEGEPLAN</a>
                            </div> -->
                        </div>
                        <v-list-item-content class="pl-0 mt-4">
                            <!--  <div class="text-overline mb-4">
                                OVERLINE
                              </div> -->
                              <v-list-item-title class="text-h5 mb-1">
                                  <!-- <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                                  <v-divider></v-divider>
                                  <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p> -->
                                  
                                  <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                                  <v-btn text outlined to="/perfil">Mi perfil</v-btn>
                              </v-list-item-title>
                              <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                            </v-list-item-content>

                    </div>
                    <!--end:: card-body pt-4 -->
                </div>
                <!--end::card card-custom card-stretch-->
            </div>
            <!-- end:: flex-row-auto w-->
        </div>

        <div class="row">
             <div class="col col-xl-6 col-md-4 col-sm-6 col-xs-12 mt-2">
              <template class="d-none">
                <v-card
                  class="mx-auto"
                  outlined
                  height="332"
                  dark
                  style="background: rgb(20,136,194); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(20,136,194,1) 0%, rgba(20,117,166,1) 68%); "
                >
                  <v-list-item three-line>
                    <!-- <v-list-item-avatar
                      tile
                      size="150"
                      color="grey"
                    ></v-list-item-avatar> -->

                    <v-list-item-content class="pl-4 mt-4">
                    <!--  <div class="text-overline mb-4">
                        OVERLINE
                      </div> -->
                      <v-list-item-title class="text-h5 mb-1">
                          <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperantes<!--  <br>Norte-Sur --> </h3>
                          <v-divider></v-divider>
                          <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p>
                          
                          <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                          <v-btn text outlined to="/proyectos/cooperacion/externo/registro">Cartera de proyectos</v-btn>
                      </v-list-item-title>
                      <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                    </v-list-item-content>

                  
                  </v-list-item>

                    <!-- <v-card-actions>
                      <v-btn
                        outlined
                        rounded
                        text
                      >
                        Button
                      </v-btn>
                    </v-card-actions> -->
                </v-card>
              </template>
             </div>


             <div class="col-md-6 col-sm-4 col-xs-12 mt-2">
                

                
                <div class="card card-custom gutter-b"  style="height: 332px; background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                    <div class="card-body d-flex align-items-center">
                        <div>
                            <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Estrategias de cooperación </h3>
                            <p class="text-h6 text-justify text-dark-50">Estrategias registradas del cooperante</p>
                            <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/administracion/estrategias">Administrar estrategias</v-btn>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    </div> <!--fin:: container-->
        
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
    
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inicio" }]);
    },

    computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),

    getFullName() {
      return (
        this.$store.state.auth.user.nombres +
        " " +
        this.$store.state.auth.user.apellidos
      );
    },

    firstName:{
      get(){
         return this.$store.state.auth.user.nombres.split(' ')[0];
      }
    },

    lastName:{
      get(){
         return this.$store.state.auth.user.apellidos.split(' ')[0];
      }
    },

     email:{
      get(){
         return this.$store.state.auth.user.correo;
      }
    },

    usuario:{
      get(){
         return this.$store.state.auth.user.usuario;
      }
    },

    currentRoles:{
      get(){
         return this.$store.state.auth.roles;
      }
    },
  }
}
</script>
<style lang="">
    
</style>